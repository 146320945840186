var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("MatchGameLayout", {
    staticClass: "fact-match",
    class: { compress: _vm.isHost },
    attrs: { isDraggable: _vm.isDisabled, checkMove: _vm.handleMove },
    on: { handleDragEnd: _vm.handleDragEnd },
    scopedSlots: _vm._u([
      {
        key: "top-items",
        fn: function () {
          return _vm._l(_vm.players, function (player) {
            return _c("FactMatchPlayer", {
              key: "fact-match-player-" + player.id,
              staticClass: "fact-match__column",
              attrs: {
                userInfo: _vm.getUserInfo(player.id),
                teamIcon: _vm.getTeam(player.id).icon,
                userID: player.id,
                imageOnly: _vm.isImageMatch,
                imageURL: _vm.getTopRowImage(player),
              },
            })
          })
        },
        proxy: true,
      },
      {
        key: "bottom-items",
        fn: function () {
          return _vm._l(_vm.facts, function (item) {
            return _c("FactCard", {
              key: item.id,
              staticClass: "fact-match__column",
              attrs: {
                selected: item.id === _vm.target,
                disabled: _vm.isDisabled,
                isImage: _vm.isImageMatch,
                image: _vm.getUserImage(item.id),
                firstName: item.fact.firstName,
                correct: item.fact.correct,
              },
              on: {
                mousedown: function ($event) {
                  return _vm.onMouseDown(item.id)
                },
                mouseup: function ($event) {
                  return _vm.onMouseDown(null)
                },
                dragend: function ($event) {
                  return _vm.onMouseDown(null)
                },
              },
            })
          })
        },
        proxy: true,
      },
      {
        key: "submit",
        fn: function () {
          return [
            _c(
              "RtbButton",
              {
                staticClass: "font-bold capitalize text-lg px-3",
                attrs: { disabled: !_vm.showSubmit },
                on: {
                  click: function ($event) {
                    return _vm.onSubmit()
                  },
                },
              },
              [_vm._v(" " + _vm._s(_vm.submitText) + " ")]
            ),
          ]
        },
        proxy: true,
      },
      {
        key: "pointer",
        fn: function () {
          return [
            _c("DragPointer", {
              attrs: {
                rootClassname: ".fact-match",
                elementClassname: ".fact-card",
              },
            }),
          ]
        },
        proxy: true,
      },
    ]),
    model: {
      value: _vm.facts,
      callback: function ($$v) {
        _vm.facts = $$v
      },
      expression: "facts",
    },
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }