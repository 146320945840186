<template>
  <MatchGameLayout
    v-model="facts"
    class="fact-match"
    :class="{ compress: isHost }"
    :isDraggable="isDisabled"
    :checkMove="handleMove"
    @handleDragEnd="handleDragEnd"
  >
    <template #top-items>
      <FactMatchPlayer
        class="fact-match__column"
        v-for="player in players"
        :key="`fact-match-player-${player.id}`"
        :userInfo="getUserInfo(player.id)"
        :teamIcon="getTeam(player.id).icon"
        :userID="player.id"
        :imageOnly="isImageMatch"
        :imageURL="getTopRowImage(player)"
      />
    </template>

    <template #bottom-items>
      <FactCard
        v-for="item in facts"
        class="fact-match__column"
        :key="item.id"
        :selected="item.id === target"
        :disabled="isDisabled"
        :isImage="isImageMatch"
        :image="getUserImage(item.id)"
        :firstName="item.fact.firstName"
        :correct="item.fact.correct"
        @mousedown="onMouseDown(item.id)"
        @mouseup="onMouseDown(null)"
        @dragend="onMouseDown(null)"
      />
    </template>

    <template #submit>
      <RtbButton
        class="font-bold capitalize text-lg px-3"
        @click="onSubmit()"
        :disabled="!showSubmit"
      >
        {{ submitText }}
      </RtbButton>
    </template>

    <template #pointer>
      <DragPointer rootClassname=".fact-match" elementClassname=".fact-card" />
    </template>
  </MatchGameLayout>
</template>

<script>
import { mapGetters } from "vuex"
import { RtbButton } from "@/components/ui"
import draggable from "vuedraggable"
import User from "@shared/User"
import MatchGameLayout from "./Parts/MatchGameLayout.vue"
import DragPointer from "./Parts/DragPointer.vue"
import FactCard from "./Parts/FactCard.vue"
import * as moment from "moment"

const FactMatchPlayer = () =>
  import("@/components/GroupTeams/Common/Player/FactMatchPlayer.vue")

import useMatchGame from "./useMatchGame"

export default {
  name: "FactMatch",
  components: {
    MatchGameLayout,
    FactMatchPlayer,
    FactCard,
    DragPointer,
    RtbButton,
    draggable
  },
  setup() {
    const { isDisabled, showSubmit, submitText, isCompleted } = useMatchGame()
    return { isDisabled, showSubmit, submitText, isCompleted }
  },
  data() {
    return {
      facts: [],
      target: null
    }
  },
  created() {
    // not reactive
    this.buffer = {
      movingIndex: 0,
      futureIndex: 0
    }
  },
  mounted() {
    // initialize local copy
    if (this.otherTeamFacts) {
      this.facts = this.otherTeamFacts.map(obj => ({
        ...obj,
        index: parseInt(obj.index)
      }))
    } else {
      console.log("this.otherTeamFacts is not yet avilable")
    }
  },
  watch: {
    // watch for the remote and updte the local copy
    otherTeamFacts: {
      handler: function (newValue) {
        if (this.isPresenter && this.facts.length) return
        let array = []
        if (newValue) {
          if (newValue.length) {
            array = newValue.map(obj => ({
              ...obj,
              index: parseInt(obj.index)
            }))
          }
        }
        this.facts = array
      },
      deep: true
    }
  },
  computed: {
    ...mapGetters(["onlineUsersArray", "orgID", "gameID", "isScribe"]),
    ...mapGetters("auth", ["isAudit", "isSpectator", "clientID"]),
    ...mapGetters("GameUsers", ["users"]),
    isPresenter() {
      return User.isPresenter(this.user)
    },
    isImageMatch() {
      return this.otherTeamFacts.some(obj => obj?.fact?.correct?.image)
    },
    otherTeamFacts() {
      if (!this.team) return []
      if (!Array.isArray(this.team.otherTeamFacts)) return []
      return this.team.otherTeamFacts
    },
    players() {
      const facts = this.facts
      const onlineParticipants = this.onlineUsersArray || []
      if (!facts) return []

      const array = this.facts.map(obj => {
        const { fact } = obj
        const player = onlineParticipants.find(({ id }) => fact.userID === id)
        if (player) {
          return {
            ...obj,
            ...player
          }
        } else {
          return {
            ...obj,
            id: fact.userID,
            index: obj.index,
            image: this.getUserImage(fact.userID)
          }
        }
      })

      // the only purpose of this index is to
      // keep the video/image elements stable
      // in the array
      array.sort((a, b) => a.index - b.index)
      return array
    },
    isHost() {
      return this.user.role === "facilitator"
    },
    teams() {
      return this.$store.getters.chats
    },
    team() {
      if (this.$store.getters["group/canViewerSelectGlobalTeam"]) {
        return this.teams[this.$store.getters["group/globalTeamID"]]
      } else {
        return this.teams[this.user.teamID]
      }
    },
    user() {
      return this.$store.getters.user
    },
    currentMissionID() {
      return this.$store.getters.currentMission
    },
    currentMission() {
      return this.$store.getters.getCurrentMission
    },
    teamMission() {
      if (
        this.currentMission.playType == "Team: Speed Matters" ||
        this.currentMission.playType == "Team: Speed Does Not Matter" ||
        this.currentMission.playType == undefined
      ) {
        return true
      }
      return false
    },
    speedMatters() {
      if (
        this.currentMission.playType == "Team: Speed Matters" ||
        this.currentMission.playType == "Individual: Speed Matters" ||
        this.currentMission.playType == undefined
      ) {
        return true
      }
      return false
    }
  },
  methods: {
    handleDragEnd() {
      const { movingIndex, futureIndex } = this.buffer
      const futureItem = this.facts[futureIndex]
      const movingItem = this.facts[movingIndex]
      if (futureItem !== undefined && movingItem !== undefined) {
        const facts = this.facts.slice(0)
        facts[futureIndex] = movingItem
        facts[movingIndex] = futureItem

        this.facts = facts

        this.onUpdate()
      }
    },
    handleMove(e) {
      const { index: movingIndex, futureIndex } = e.draggedContext
      this.buffer = { movingIndex, futureIndex }
      // disable sort
      return false
    },
    getTopRowImage(obj) {
      if (this.isImageMatch) {
        return obj.fact?.answer?.[0]?.image
      } else {
        return obj.image
      }
    },
    getUserImage(userID) {
      if (!this.users) return null
      const { image } = this.users[userID] || {}
      return image
    },
    onMouseDown(factID) {
      this.target = factID
    },
    onTeamUpdate(teamID, facts) {
      const obj = {}
      obj.otherTeamFacts = facts
      obj.id = teamID
      this.$store.dispatch("updateTeam", obj)
    },
    // update current (local) team
    onUpdate() {
      this.onTeamUpdate(this.team.id, this.facts)
    },
    onSubmit() {
      console.log("onSubmit")
      if (this.isCompleted) return console.log("Cannot submit one more time")

      if (this.facts.length !== this.players.length)
        throw new Error("Somethign bad happend")

      const correct = this.facts.filter(
        (obj, idx) => this.players[idx].id === obj.fact.userID
      )
      const incorrect = this.facts.filter(
        (obj, idx) => this.players[idx].id !== obj.fact.userID
      )

      const numOfFacts = this.facts.length
      const num = parseInt(this.currentMission.points)
      var points = isNaN(num) ? 0 : num
      points = points / numOfFacts
      const score = correct.length * points
      const answer = []

      correct.forEach(obj => {
        answer.push({
          name: obj.name,
          factUserID: obj.fact.userID,
          fact: obj.fact,
          result: true
        })
      })

      incorrect.forEach(obj => {
        answer.push({
          name: obj.name,
          factUserID: obj.fact.userID,
          fact: obj.fact,
          result: false
        })
      })

      // this.addFactMatchPlay(score, answer)
      this.$store.dispatch("addFactMatchPlay", { score, answer })
    },
    getUserInfo(userID) {
      const { firstname, lastname } = this.users[userID] || {}
      return { firstname, lastname }
    },
    getTeam(userID) {
      var teamID = this.getTeamID(userID)
      return (this.teams && this.teams[teamID]) || {}
    },
    async addFactMatchPlay(score, answer) {
      const results = answer.filter(obj => obj.result)

      let teamID = this.user?.teamID
      if (this.isHost) {
        teamID = this.$store.getters["group/globalTeamID"]
      }

      const sTime = new Date()
      const teamScore = this.teams?.[teamID]?.totalScore ?? 0
      const currentMission = this.currentMission

      const userPlay = {
        behavior: currentMission.behavior,
        correct: "Fact Match",
        mission: currentMission.name,
        answer: answer,
        missionID: this.currentMissionID,
        points: currentMission.points,
        score: score,
        result: results?.length > 0,
        show: true,
        completed: true,
        teamID: teamID,
        time: moment(sTime).unix(),
        userID: this.user?.id,
        username: this.user?.name,
        firstName: this.user?.firstname
      }

      console.log("HERE's THE PLAY WE ARE SETTING", userPlay)
      this.$store.dispatch("addPlay", userPlay)

      const factScorePayload = {
        userID: this.user?.id,
        teamID: teamID,
        teamScore: teamScore + score,
        totalScore: score,
        score,
        clientID: this.clientID
      }

      await Promise.all([
        this.$store.dispatch("setScore", factScorePayload),
        this.$store.dispatch("setTeamScore", factScorePayload)
      ])
    },
    getTeamID(userID) {
      if (!userID) return null
      if (!this.users) return null
      const user = this.users[userID]
      if (!user) return null
      return user.teamID
    }
  }
}
</script>

<style lang="scss">
.fact-match {
  color: #fff;
  --fact-match-col-width: calc(27vh - 50px);

  .match-game-layout__top,
  .match-game-layout__draggable-row {
    height: auto;
  }

  &__column {
    width: var(--fact-match-col-width);
    max-width: var(--fact-match-col-width);
    flex-basis: var(--fact-match-col-width);
    flex-shrink: 0;
    margin: 4px;
  }

  &.compress {
    --fact-match-col-width: 24vh;
  }

  .mobile-layout & {
    --fact-match-col-width: 120px;
  }
}
</style>
